<template>
  <v-col
    cols="12"
    :class="{
      'd-flex flex-row-reverse align-baseline': !mobile,
      'py-0': mobile
    }"
  >
    <div v-if="!mobile">
      <v-badge
        :value="!!message"
        class="font-italic"
        :color="messageColor"
        :content="message"
        tile
        left
        overlap
      >
        <v-btn
          id="createPatient"
          elevation="0"
          color="primary"
          :disabled="disabled"
          @click="createPatient()"
        >
          {{ $t("addPatient") }}
        </v-btn>
      </v-badge>
    </div>
    <div v-else>
      <v-btn
        id="createPatient"
        elevation="0"
        color="primary"
        :disabled="disabled"
        @click="createPatient()"
        block
      >
        {{ $t("addPatient") }}
      </v-btn>
    </div>
  </v-col>
</template>

<script>
import { PatientBuilder } from "spiral";

export default {
  props: ["siteCode"],
  data() {
    return {
      allSites: []
    };
  },
  computed: {
    disabled() {
      return !!this.message;
    },
    site() {
      return this.allSites.find(s => s.siteCode == this.siteCode);
    },
    message() {
      if (this.site == undefined) return this.$t("noSite");
      if (this.site.frozen) return this.$t("frozenSite");
      return undefined;
    },
    messageColor() {
      return this.site?.frozen ? "danger" : "info";
    }
  },
  methods: {
    createPatient() {
      const site = this.allSites.find(s => s.siteCode == this.siteCode);
      const patientBuilder = new PatientBuilder(this.currentStudy, "", site);
      patientBuilder.interview(this.currentStudy.workflows[0].info);
      patientBuilder.interview(this.currentStudy.workflows[0].sequence[0]);
      const patient = patientBuilder.build();
      const interview = patient.interviews.last;
      this.setPatient(patient);
      this.setPageSet(interview.pageSet);
      this.setInterview(interview);
      this.$router.push({ name: "Patient creation" });
    }
  },
  async mounted() {
    this.allSites = await this.drivers.siteDriver.getAll(this.currentStudy);
  },
  i18n: {
    messages: {
      en: {
        addPatient: "Add a patient",
        noSite: "Please select a site",
        frozenSite: "Site is frozen"
      },
      fr: {
        addPatient: "Ajouter un patient",
        noSite: "Veuillez sélectionner un site",
        frozenSite: "Le site est gelé"
      }
    }
  }
};
</script>
