var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Patients")]),_c('v-data-table',{attrs:{"id":"PatientListTable","items":_vm.tabItems,"headers":_vm.headers,"loading":_vm.loading,"loading-text":_vm.$t('events.loading'),"flat":""},scopedSlots:_vm._u([{key:`item.lastInterviewInfo`,fn:function({ item }){return [_c('page-set-label',{attrs:{"interview":{
            date: item.lastInterviewInfo.date,
            status: item.lastInterviewInfo.status,
            pageSet: { type: item.lastInterviewInfo.type }
          }}}),(
            _vm.currentStudy.options.showFillRate && item.lastInterviewInfo.date
          )?_c('fill-rate',{staticClass:"lastInterviewfillRate",attrs:{"max":1,"value":item.lastInterviewInfo.fillRate}}):_vm._e()]}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"d-flex"},[_c('v-chip',{staticClass:"mr-2 openCRF",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openCRF(item.patient)}}},[_vm._v("CRF")]),_c('v-chip',{staticClass:"mr-2 openHome",attrs:{"color":"success"},on:{"click":function($event){return _vm.openHome(item.patient)}}},[_vm._v(_vm._s(_vm.getLabel( "mlstring", {}, _vm.currentStudy.workflows[0].info.type, _vm.$i18n )))]),_c('print-crf',{attrs:{"patient":item.patient,"compact":true}})],1)]}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }