<template>
  <print-button
    :id="`print-${patient}`"
    :css="css"
    :get="get"
    :title="title"
    :compact="compact"
  ></print-button>
</template>

<script>
import PrintButton from "./PrintButton.vue";

export default {
  components: { PrintButton },
  props: ["patient", "compact"],
  computed: {
    css() {
      return "/print/crf.css";
    },
    patientCode() {
      return this.patient.patientCode ?? this.patient ?? "blank";
    },
    lang() {
      return this.currentStudy.options.languages.includes(this.$i18n.locale)
        ? this.$i18n.locale
        : this.currentStudy.options.defaultLang;
    },
    get() {
      return `print/${this.currentStudy.name}/${this.patientCode}?lang=${this.lang}`;
    },
    title() {
      if (this.patientCode == "blank" || this.patientCode == "annotated")
        return this.$t(this.patientCode);
      return this.$t("form");
    }
  },
  i18n: {
    messages: {
      en: {
        form: "form",
        blank: "blank CRF",
        annotate: "annotated CRF"
      },
      fr: {
        form: "formulaire",
        blank: "CRF vierge",
        annotated: "CRF annoté"
      }
    }
  }
};
</script>
