<template>
  <v-row>
    <v-col cols="12" :class="{ 'px-0': mobile }">
      <patient-filter @filterchanged="filter = $event"></patient-filter>
    </v-col>
    <v-col cols="12" :class="{ 'px-0': mobile }" v-if="checkRole">
      <add-patient :siteCode="idSiteFilter"></add-patient>
    </v-col>
    <v-col cols="12" :class="{ 'px-0': mobile }">
      <patient-list :filter="filter"></patient-list>
    </v-col>
  </v-row>
</template>

<script>
import PatientFilter from "../components/patient/PatientFilter";
import PatientList from "../components/patient/PatientList";
import AddPatient from "../components/patient/AddPatient";

import { mapGetters } from "vuex";

export default {
  components: {
    PatientFilter,
    PatientList,
    AddPatient
  },
  data: function() {
    return {
      filter: {}
    };
  },
  computed: {
    ...mapGetters("auth", ["isInRole"]),
    checkRole() {
      const canStartWorkflow = this.currentWorkflow.pageSets.includes(
        this.currentStudy.mainWorkflow.start
      );
      const canWrite = this.isInRole(
        "investigator",
        "developer",
        "cst",
        "deo",
        "dataadministrator"
      );
      return canStartWorkflow && canWrite;
    },
    idSiteFilter() {
      return this.filter.fixed?.["@SITE"];
    }
  }
};
</script>
