<template>
  <v-col cols="12">
    <v-card>
      <v-card-title>Patients</v-card-title>
      <v-data-table
        id="PatientListTable"
        :items="tabItems"
        :headers="headers"
        :loading="loading"
        :loading-text="$t('events.loading')"
        flat
      >
        <template v-slot:[`item.lastInterviewInfo`]="{ item }">
          <page-set-label
            :interview="{
              date: item.lastInterviewInfo.date,
              status: item.lastInterviewInfo.status,
              pageSet: { type: item.lastInterviewInfo.type }
            }"
          ></page-set-label>
          <fill-rate
            v-if="
              currentStudy.options.showFillRate && item.lastInterviewInfo.date
            "
            class="lastInterviewfillRate"
            :max="1"
            :value="item.lastInterviewInfo.fillRate"
          ></fill-rate>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <div class="d-flex">
            <v-chip
              class="mr-2 openCRF"
              color="primary"
              @click="openCRF(item.patient)"
              >CRF</v-chip
            >
            <v-chip
              class="mr-2 openHome"
              color="success"
              @click="openHome(item.patient)"
              >{{
                getLabel(
                  "mlstring",
                  {},
                  currentStudy.workflows[0].info.type,
                  $i18n
                )
              }}</v-chip
            >
            <print-crf :patient="item.patient" :compact="true"></print-crf>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import FillRate from "../form/FillRate";
import PageSetLabel from "../form/PageSetLabel.vue";
import PrintCrf from "../tools/PrintCrf.vue";
import patientFilters from "../../mixin/patientFilters";
import { formatCode } from "spiral";

export default {
  props: ["filter"],
  data() {
    return {
      allPatients: [],
      perPage: 5,
      fixedHeaders: [
        {
          text: this.$t("domain.noSite"),
          value: "site",
          class: "tableContentSiteCode"
        },
        {
          text: this.$t("domain.noPatient"),
          value: "patientCode",
          class: "tableContentPatientId"
        },
        {
          text: this.$t("lastPageSet"),
          value: "lastInterviewInfo",
          class: "tableContentLastInterviewInfo"
        }
      ],
      loading: false
    };
  },
  computed: {
    headers: function() {
      let fixedHeaders = this.fixedHeaders;
      if (this.currentUser.sites.length == 1) {
        fixedHeaders = this.fixedHeaders.slice(1);
      }
      return fixedHeaders.concat(this.pinsHeaders, this.actionHeaders);
    },
    pinsHeaders: function() {
      return this.currentStudy.pins.map(pin => {
        return {
          text: this.getLabel("mlstring", {}, pin.pin, this.$i18n),
          value: this.getLabel("mlstring", {}, pin.pin, this.$i18n)
        };
      });
    },
    actionHeaders: function() {
      return { text: "Actions", value: "action" };
    },
    tabItems: function() {
      return this.filteredPatients.map(p => {
        const currentInterview = p.currentInterview;
        return {
          site: p.siteCode,
          patientCode: formatCode(p, this.currentStudy.options),
          patient: p,
          lastInterviewInfo: {
            date: currentInterview?.date,
            type: currentInterview?.type ?? {},
            status: currentInterview?.status ?? "incomplete",
            fillRate: currentInterview?.fillRate ?? 0
          },
          ...Object.fromEntries(
            this.currentStudy.pins.map(pin => {
              const header = this.getLabel("mlstring", {}, pin.pin, this.$i18n);
              if (pin.variableName in p.pins) {
                const item = p.pins[pin.variableName];
                const { name: typeName, ...args } = item.type;
                return [
                  header,
                  this.getLabel(typeName, args, item.value, this.$i18n)
                ];
              }
              return [header, undefined];
            })
          )
        };
      });
    },
    filteredPatients: function() {
      const customFilter = this.filter.custom?.map(f => {
        return { [f.pin.variableName]: f.value };
      });
      const query = Object.assign(
        {},
        this.filter.fixed,
        ...(customFilter ?? [])
      );
      const apply = this.patientFilters.apply(query);
      return this.allPatients.filter(p => apply(p));
    }
  },
  methods: {
    async openCRF(p) {
      const sites = await this.drivers.siteDriver.getAll(this.currentStudy);
      const patient = await this.drivers.patientDriver.getByPatientCode(
        this.currentStudy,
        sites,
        p.patientCode
      );
      this.setPatient(patient);
      this.setInterview(patient.currentInterview(this.currentWorkflow));
      this.$router.push({
        name: "Patient CRF",
        params: { patientCode: patient.patientCode }
      });
    },
    async openHome(p) {
      const sites = await this.drivers.siteDriver.getAll(this.currentStudy);
      const patient = await this.drivers.patientDriver.getByPatientCode(
        this.currentStudy,
        sites,
        p.patientCode
      );
      this.setPatient(patient);
      this.setInterview(patient.interviews[0]);
      this.$router.push({
        name: "Patient CRF",
        params: { patientCode: patient.patientCode }
      });
    }
  },
  mixins: [patientFilters],
  async mounted() {
    this.loading = true;
    const site =
      this.currentUser.sites.length == 1
        ? this.currentUser.sites[0]
        : undefined;
    const userSiteCodes = this.currentUser.sites.map(s => s.siteCode);
    this.allPatients = await this.drivers.summaryDriver
      .getPatientSummaries(this.currentStudy, site, [
        "patientCode",
        "siteCode",
        "currentInterview",
        "interviewCount",
        "pins",
        "included"
      ])
      .then(res => res.filter(p => userSiteCodes.includes(p.siteCode)));
    this.setPatient(undefined);
    this.loading = false;
  },
  components: {
    FillRate,
    PageSetLabel,
    PrintCrf
  },
  i18n: {
    messages: {
      en: { pageSet: "@:domain.pageSet", lastPageSet: "Last @.lower:pageSet" },
      fr: {
        pageSet: "@:domain.pageSet",
        lastPageSet: "Dernière @.lower:pageSet"
      }
    }
  }
};
</script>
<style>
.table th,
.table td {
  vertical-align: middle;
}
</style>
